<template>
  <div class="ls-page">
    <div class="ps-section__header ls-page-header-admin">
      <h1 class="container ls-page-header-title">Maintain Runners</h1>
    </div>

    <div class="ps-section__content ls-page-content">
      <div class="row">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
          <router-link :to="{ name: 'admin_runners_runners' }">
            <div class="card ls-admin-card">
              <div class="card-body">
                <div class="card-text ls-center">
                  {{ runsCount }}
                </div>
              </div>
              <div class="card-footer ls-center">
                Runner Requests
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
          <router-link :to="{ name: 'admin_runners_requests' }">
            <div class="card ls-admin-card">
              <div class="card-body">
                <div class="card-text ls-center">
                  {{ runnersCount }}
                </div>
              </div>
              <div class="card-footer ls-center">
                Runners
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
          <router-link :to="{ name: 'admin_runners_categories' }">
            <div class="card ls-admin-card">
              <div class="card-body">
                <div class="card-text ls-center">
                  {{ runnerCategoriesCount }}
                </div>
              </div>
              <div class="card-footer ls-center">
                Runners Categories
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "AdminRunner",
  mounted() {
    this.getRunnerDetails();
    this.getRunnerCategories();
  },
  data() {
    return {
      runsCount: 0,
      runnersCount: 0,
      runnerCategoriesCount: 0,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  methods: {
    getRunnerDetails() {
      axios.get("v1/getrunner/runners").then((response) => {
        console.log("Runners : " + JSON.stringify(response.data.data));
        this.runnersCount = response.data.data.length;
      });

      axios.get("v1/getrunner/runner-categories").then((response) => {
        console.log("Get Runner Cat : " + JSON.stringify(response.data));
        this.runnerCategoriesCount = response.data.length;
      });

      axios.get("v1/getrunner/get-runners").then((response) => {
        console.log("Get Runners : " + JSON.stringify(response.data));
        this.runsCount = response.data.data.length;
      });
    },
  },
};
</script>
